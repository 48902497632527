import {
    Button,
    Card,
    CardContent,
    CardHeader,
    CircularProgress,
    Container,
    Grid,
    LinearProgress,
    MenuItem,
    Select,
    Table,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';

import moment from 'moment';
import { useState } from 'react';
import { useGetList } from 'react-admin';
import { BiRefresh } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';
import CopyByDateOfBirthDialog from '../components/CopyByDateOfBirthDialog';
import useDashboard from './useDashboard';

export const Dashboard = () => {
    const [showKidsDOBModal, setShowKidsDOBModal] = useState(false);
    const navigation = useNavigate();
    const {
        handleChangeMyCompany,
        handleChangeMyExpert,
        handleCopyNewbornEmails,
        handleCopyNoWixEmails,
        activeNewborns,
        company,
        groupSessionsStats,
        newbornSentCount,
        expertId,
        expertStats,
        passItOnStats,
        randomPassItOnStats,
        totalPrivateSessions,
        totalPrivateSessionsToday,
        memberStats,
        dating,
        nps,
        passItOnDiff,
    } = useDashboard();
    const companies = useGetList('company', {
        pagination: { perPage: 1000, page: 1 },
        sort: { field: 'english_name', order: 'ASC' },
    });

    return (
        <>
            <CopyByDateOfBirthDialog open={showKidsDOBModal} onDismiss={() => setShowKidsDOBModal(false)} />
            <Container style={{ paddingTop: 30 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={4}>
                        <Card style={{ height: '100%' }}>
                            <CardHeader title='הגדרות שלי' />
                            <CardContent>
                                <table>
                                    <thead>
                                        <th className='w-full' />
                                        <th />
                                    </thead>
                                    <tr>
                                        <td className='td-row-title'>בחירת חברה</td>
                                        <td>
                                            <Select
                                                className='w-full'
                                                size={'small'}
                                                displayEmpty
                                                label='החברה שלי'
                                                value={company}
                                                onChange={(e) => {
                                                    handleChangeMyCompany(e.target.value);
                                                }}>
                                                {companies.data?.map((company) => (
                                                    <MenuItem value={company?._id}>{company.english_name}</MenuItem>
                                                ))}
                                            </Select>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='td-row-title'>בחירת עמוד יועץ</td>
                                        <td>
                                            <Select
                                                className='w-full'
                                                size='small'
                                                displayEmpty
                                                label=''
                                                value={expertId}
                                                onChange={(e) => {
                                                    handleChangeMyExpert(e.target.value);
                                                }}>
                                                <MenuItem value={'null'}>ללא</MenuItem>
                                                {expertStats.data?.experts.map((expert: any) => (
                                                    <MenuItem value={expert?._id}>{expert.name}</MenuItem>
                                                ))}
                                            </Select>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='td-row-title'>
                                            מיילים עובדי חברות
                                            <p className='text-xs leading-tight'> (ללא Wix & MyHeritage)</p>
                                        </td>
                                        <td>
                                            <Button onClick={handleCopyNoWixEmails}>{'העתקה'}</Button>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='td-row-title'>מיילים מנוי לידה</td>
                                        <td>
                                            <Button disabled={Boolean(activeNewborns.loading)} onClick={handleCopyNewbornEmails}>
                                                העתקה
                                            </Button>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='td-row-title'>העתקה לפי גיל ילדים</td>
                                        <td>
                                            <Button onClick={() => setShowKidsDOBModal(true)}>העתקה</Button>
                                        </td>
                                    </tr>
                                </table>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Card
                            style={{ height: '100%' }}
                            onClick={() => {
                                navigation('/member?order=DESC&page=1&perPage=10&sort=is_new');
                            }}>
                            <CardHeader title='משתמשים' />
                            <CardContent>
                                {memberStats.loading ? (
                                    <CircularProgress />
                                ) : (
                                    <div>
                                        <table className='border-separate border-spacing-x-3 w-full'>
                                            <tr style={{}}>
                                                <td style={{ textAlign: 'right', fontWeight: '600', fontSize: 20 }}>סך הכל</td>
                                                <td>
                                                    <span className='text-2xl font-semibold'>{memberStats.data?.totalUsers}</span>
                                                </td>
                                            </tr>
                                            <tr className='font-bold'>
                                                <td />
                                                <td>מובייל</td>
                                                <td>ווב</td>
                                            </tr>
                                            <tr>
                                                <td style={{ textAlign: 'right' }}>התחברו היום</td>
                                                <td>{<span>{`${memberStats.data?.todayUsers.mobile}`}</span>}</td>
                                                <td>{<span>{`${memberStats.data?.todayUsers.web}`}</span>}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ textAlign: 'right' }}>חדשים </td>
                                                <td>{<span>{`${memberStats.data?.newUsers.mobile}`}</span>}</td>
                                                <td>{<span>{`${memberStats.data?.newUsers.web}`}</span>}</td>
                                            </tr>
                                            <tr>
                                                <br />
                                            </tr>

                                            <tr className='font-bold'>
                                                <td className='' />
                                                <td className='min-w-20'>היום</td>
                                                <td>סה"כ</td>
                                            </tr>

                                            <tr>
                                                <td className='text-start font-bold'>הרצאות וסדנאות</td>
                                                <td>{groupSessionsStats.loading ? <LinearProgress /> : groupSessionsStats.data?.todaysEnrollments}</td>
                                            </tr>
                                            <tr>
                                                <td className='text-start font-bold'>מפגשים פרטניים</td>
                                                <td>{totalPrivateSessionsToday.data?.data}</td>
                                                <td>{totalPrivateSessions.data?.data}</td>
                                            </tr>
                                        </table>
                                    </div>
                                )}
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Card style={{ height: '100%', position: 'relative' }}>
                            <CardHeader
                                title={
                                    <span className=''>
                                        {'Net Promoter Score'.split(' ').map((word) => (
                                            <span className='me-2'>
                                                <span className='text-3xl font-bold'>{word[0]}</span>
                                                <span className='font-medium text-xl'>{word.slice(1)}</span>
                                            </span>
                                        ))}
                                    </span>
                                }
                                titleTypographyProps={{ fontSize: 30, fontWeight: 600 }}
                            />
                            <CardContent>
                                <div className=''>
                                    <span className='font-extrabold text-4xl'>{nps}</span>
                                    <div className='mt-2'>
                                        <img alt='nps-help' src={process.env.PUBLIC_URL + '/nps.png'} height={'auto'} width={'100%'} />
                                    </div>
                                </div>
                            </CardContent>
                        </Card>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                        <Card style={{}}>
                            <CardHeader style={{ paddingBottom: 0 }} title='פרגונים' />
                            <CardContent style={{ height: 200, overflowY: 'scroll' }}>
                                <table style={{ tableLayout: 'fixed' }}>
                                    <thead className='text-start bg-gray-200'>
                                        <th className='text-start'>חברה</th>
                                        <th className='text-center'>פרגונים שנשלחו</th>
                                    </thead>
                                    <tr className='font-bold bg-gray-100'>
                                        <td className='text-start text-sm w-full'>סה"כ</td>
                                        <td className='min-w-44 text-center'>
                                            <div className='flex flex-row justify-center items-center gap-2'>
                                                {passItOnStats.data?.reduce((curr, next) => (curr += next.count), 0)}
                                                {passItOnDiff?.data ? <span className='text-[12px] font-medium text-green-800'>{` (+${passItOnDiff.data.diff})`}</span> : ``}
                                            </div>
                                        </td>
                                    </tr>
                                    {passItOnStats.data?.map((company: { count: number; companyName: string }) => (
                                        <tr>
                                            <td className='text-start text-sm w-full'>{company.companyName}</td>
                                            <td className='min-w-44 text-center'>{company.count}</td>
                                        </tr>
                                    ))}
                                </table>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <Card style={{}}>
                            <CardHeader
                                style={{ paddingBottom: 0 }}
                                title='פרגונים'
                                action={
                                    <Button onClick={randomPassItOnStats.fetchAgain}>
                                        <BiRefresh />
                                    </Button>
                                }
                            />
                            <CardContent style={{ height: 200, overflowY: 'scroll' }}>
                                <table style={{ tableLayout: 'fixed' }}>
                                    <thead className='text-start bg-gray-200'>
                                        <th className='text-start'>פרגון</th>
                                        <th className='text-center'>חברה</th>
                                    </thead>
                                    {randomPassItOnStats.data?.map(({ text, company }) => (
                                        <tr>
                                            <td className='w-full text-start'>{text}</td>
                                            <td className='text-center text-sm '>{company}</td>
                                        </tr>
                                    ))}
                                </table>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Card style={{ height: '100%' }}>
                            <CardHeader title='מומחים בגרופהאג' />
                            <CardContent>
                                {expertStats.loading ? (
                                    <LinearProgress />
                                ) : (
                                    <span>
                                        <Typography variant='h3'>{expertStats.data?.total}</Typography>
                                        <Typography variant='body1'>{`${expertStats.data?.registeredExperts} נרשמו למערכת`}</Typography>
                                        <Typography variant='body1'>{`${expertStats.data?.synchedExperts} השלימו סינכרון יומן`}</Typography>
                                        <Typography variant='body1'>{`${expertStats.data?.safeSpaceExperts} מטפלים במרחב הבטוח`}</Typography>
                                    </span>
                                )}
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Card style={{ height: '100%' }}>
                            <CardHeader title='נתונים נוספים' />
                            <CardContent>
                                <Table dir='rtl' size='small'>
                                    <TableRow>
                                        <TableCell style={{ textAlign: 'right' }} dir='rtl'>
                                            מנויי לידה
                                        </TableCell>
                                        <TableCell>{<Typography>{newbornSentCount.data?.count}</Typography>}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell style={{ textAlign: 'right' }} dir='rtl'>
                                            רשומים לדייטינג בהייטק
                                        </TableCell>
                                        <TableCell>{<Typography>{dating.data}</Typography>}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell style={{ textAlign: 'right' }} dir='rtl'>
                                            NPS
                                        </TableCell>
                                        <TableCell>{<Typography>{nps}</Typography>}</TableCell>
                                    </TableRow>
                                </Table>
                            </CardContent>
                        </Card>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                        <Card style={{ height: '100%' }}>
                            <CardHeader title='הרצאות וסדנאות' />
                            <CardContent>
                                {groupSessionsStats.loading ? (
                                    <LinearProgress />
                                ) : (
                                    <Table size='small'>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell style={{ textAlign: 'right' }}>שעה</TableCell>
                                                <TableCell style={{ textAlign: 'right' }}>שם</TableCell>
                                                <TableCell style={{ textAlign: 'right' }}>רשומים</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableRow>
                                            <TableCell colSpan={3} style={{ textAlign: 'center', fontWeight: '600' }}>
                                                היום
                                            </TableCell>
                                        </TableRow>
                                        {groupSessionsStats?.data?.todaysSessions
                                            ?.filter((meeting) => moment(meeting.time).isSame(moment(), 'day'))
                                            .map((meeting) => (
                                                <TableRow>
                                                    <TableCell style={{ textAlign: 'right' }}>{moment(meeting.time).format('HH:mm')}</TableCell>
                                                    <TableCell style={{ textAlign: 'right' }}>{meeting.title}</TableCell>
                                                    <TableCell style={{ textAlign: 'right' }}>{meeting.registered}</TableCell>
                                                </TableRow>
                                            ))}
                                        <TableRow>
                                            <TableCell colSpan={3} style={{ textAlign: 'center', fontWeight: '600' }}>
                                                מחר
                                            </TableCell>
                                        </TableRow>
                                        {groupSessionsStats?.data?.tomorrowSessions?.map((meeting) => (
                                            <TableRow>
                                                <TableCell style={{ textAlign: 'right' }}>{moment(meeting.time).format('HH:mm')}</TableCell>
                                                <TableCell style={{ textAlign: 'right' }}>{meeting.title}</TableCell>
                                                <TableCell style={{ textAlign: 'right' }}>{meeting.registered}</TableCell>
                                            </TableRow>
                                        ))}
                                    </Table>
                                )}
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Container>
        </>
    );
};
