import { Button, IconButton, Stack, Typography } from '@mui/material';
import axios, { AxiosError } from 'axios';
import { useCallback, useState } from 'react';
import {
	BooleanInput,
	Datagrid,
	DateField,
	ExportButton,
	FilterButton,
	FunctionField,
	List,
	ReferenceField,
	ReferenceInput,
	SelectInput,
	TextField,
	TextInput,
	useNotify,
	useRefresh,
} from 'react-admin';
import { CgFileDocument } from 'react-icons/cg';
import { MdDelete } from 'react-icons/md';
import constants from '../../constants';
import { IPrivateSessionAppointment } from '../../types';
import { getLocalizedErrorMessage } from '../../utils/functions';
import CreatePrivateSession from './Dialogs/CreateSessionDialog';
import DeleteSessionDialog from './Dialogs/DeleteSessionDialog';

const PrivateSessionsList = () => {
    const refresh = useRefresh();

    const notify = useNotify();
    const handleCancelSession = useCallback(
        async (id: string, silent?: boolean) => {
            try {
                if (!id) throw new Error();
                await axios.delete(`${constants.API_URL}member/private_service/appointment/`, {
                    data: { slot_id: id, silent },
                });
                notify(`המפגש נמחק (${silent ? 'לא נשלחו מיילים' : 'נשלחו מיילים'})`, { type: 'success' });
                refresh();
            } catch (error) {
                notify(getLocalizedErrorMessage(error as AxiosError), { type: 'error' });
            } finally {
                setSessionToDelete(null);
            }
        },
        [notify, refresh]
    );
    const [sessionToDelete, setSessionToDelete] = useState<IPrivateSessionAppointment | null>(null);
    const [showCreateSessionDialog, setShowCreateSessionDialog] = useState<boolean>(false);
    return (
        <>
            <CreatePrivateSession
                open={showCreateSessionDialog}
                onClose={() => {
                    setShowCreateSessionDialog(false);
                }}
            />
            <DeleteSessionDialog
                open={sessionToDelete !== null}
                handleCancelSession={handleCancelSession}
                onClose={() => {
                    setSessionToDelete(null);
                }}
                sessionToDelete={sessionToDelete}
            />
            <List
                actions={
                    <Stack direction='row' spacing={2} style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Button
                            onClick={() => {
                                setShowCreateSessionDialog(true);
                            }}
                            variant='text'>
                            קביעת מפגש
                        </Button>
                        <FilterButton />
                        <ExportButton />
                    </Stack>
                }
                sort={{ field: 'time', order: 'DESC' }}
                filterDefaultValues={{ payment_confirm: true }}
                filters={[
                    <ReferenceInput source='host' reference='instructor' perPage={999} sort={{ field: 'name', order: 'ASC' }}>
                        <SelectInput optionText={'name'} />
                    </ReferenceInput>,
                    <TextInput source='email' />,
                    <BooleanInput label='תשלום מאושר' source='payment_confirm' defaultValue={true} />,
                ]}>
                <Datagrid bulkActionButtons={false}>
                    <DateField textAlign='right' label='תאריך' source='time' locales={['he']} />
                    <DateField textAlign='right' label='שעה' source='time' locales={['he']} showDate={false} showTime />
                    <TextField textAlign='right' label='מייל' source='email' />
                    <TextField textAlign='right' label='סוג מפגש' source='service.title.he' />
                    <ReferenceField source='host' reference='instructor/all'>
                        <TextField textAlign='right' label='מומחה' source='name' />
                    </ReferenceField>

                    <DateField textAlign='right' label='נקבעה בתאריך' source='createdAt' showTime locales={['he']} />
                    <FunctionField
                        render={(record: IPrivateSessionAppointment) => (
                            <IconButton disabled={false} color='warning' onClick={() => setSessionToDelete(record)}>
                                <MdDelete />
                            </IconButton>
                        )}
                    />
                    <FunctionField
                        render={(record: IPrivateSessionAppointment) => (
                            <Typography fontSize={10} textAlign={'center'}>
                                {record.price && !record.payment_confirm ? 'ממתין לתשלום' : ''}
                            </Typography>
                        )}
                    />
                    <FunctionField
                        render={(record: IPrivateSessionAppointment) =>
                            record?.payment?.sale_invoice_url ? (
                                <a target='_blank' rel='noopener noreferrer' href={record.payment.sale_invoice_url}>
                                    <CgFileDocument color='gray' />
                                </a>
                            ) : null
                        }
                    />
                </Datagrid>
            </List>
        </>
    );
};

export default PrivateSessionsList;
