import { Button, Checkbox, Dialog, FormControlLabel, IconButton, Typography } from '@mui/material';
import moment from 'moment';
import {
    ArrayField,
    BooleanField,
    BulkDeleteButton,
    ChipField,
    Datagrid,
    DateInput,
    FunctionField,
    Link,
    List,
    NumberField,
    ReferenceArrayField,
    SingleFieldList,
    TextInput,
    useListContext,
    useNotify
} from 'react-admin';

import axios from 'axios';
import { useState } from 'react';
import { FaLink } from 'react-icons/fa';
import constants from '../../constants';
import { downloadEncodedCSV } from '../../utils/functions';
import MyPagination from '../MyPagination';

const CopyParamsToClipBoardButton = () => {
    const notify = useNotify();
    const { selectedIds } = useListContext();
    const [showSelectFieldsModal, setShowSelectFieldsModal] = useState(false);

    const handleClick = async () => {
        try {
            const titles = selectedFields.map((field) => fields.find((_field) => _field.value === field)?.name ?? field);
            const response = await axios.post(`${constants.API_URL}/admin/future-sessions-textual`, {
                ids: selectedIds,
                fields: selectedFields,
                titles,
            });
            downloadEncodedCSV(response.data, titles, 'sessions');

            setShowSelectFieldsModal(false);
            notify('קובץ נוצר בהצלחה', { type: 'success' });
        } catch (error) {
            notify('רגע... נסו שוב', { type: 'error' });
        }
    };
    const fields = [
        { name: 'תאריך', value: 'time', defaultChecked: true },
        { name: 'כותרת מפגש (עברית)', value: 'title', defaultChecked: true },
        { name: 'כותרת מפגש (אנגלית)', value: 'english_title', defaultChecked: false },
        { name: 'תת כותרת (עברית)', value: 'sub_title.he', defaultChecked: false },
        { name: 'תת כותרת (אנגלית)', value: 'sub_title.en', defaultChecked: false },
        { name: 'תיאור (עברית)', value: 'description_lng.he', defaultChecked: false },
        { name: 'תיאור (אנגלית)', value: 'description_lng.en', defaultChecked: false },
        { name: 'קישור זום', value: 'zoom', defaultChecked: false },
        { name: 'סיסמה לזום', value: 'zoom_password', defaultChecked: false },
        { name: 'שם מומחה (עברית)', value: 'host.name', defaultChecked: true },
        { name: 'שם מומחה (אנגלית)', value: 'host.english_name', defaultChecked: false },
        { name: 'טייטל מומחה (עברית)', value: 'host.short_title.he', defaultChecked: false },
        { name: 'טייטל מומחה (אנגלית)', value: 'host.short_title.en', defaultChecked: false },
        { name: 'קישור למפגש', value: 'link', defaultChecked: true },
    ];
    const [selectedFields, setSelectedFields] = useState<string[]>(fields.filter((field) => field.defaultChecked).map((field) => field.value));
    return (
        <>
            <Dialog open={showSelectFieldsModal} onClose={() => setShowSelectFieldsModal(false)}>
                <div className='py-4 w-72 px-8'>
                    <h2>בחירת שדות</h2>
                    <div className='flex flex-col items-start'>
                        {fields.map((field) => (
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        style={{ margin: 0 }}
                                        onChange={(e) => {
                                            if (e.target.checked) setSelectedFields((prev) => [...prev, field.value]);
                                            else setSelectedFields((prev) => prev.filter((x) => x !== field.value));
                                        }}
                                        checked={selectedFields.includes(field.value)}
                                    />
                                }
                                label={field.name}
                            />
                        ))}
                    </div>
                    <div className='text-end'>
                        <Button onClick={handleClick}>העתק</Button>
                    </div>
                </div>
            </Dialog>
            <Button onClick={() => setShowSelectFieldsModal(true)}>ייצוא הרצאות וסדנאות</Button>
        </>
    );
};
const MeetingList = () => {
    const notify = useNotify();

    return (
        <>
            <List
                filterDefaultValues={{ from_date: moment().startOf('date').toDate() }}
                sort={{ field: 'time', order: 'ASC' }}
                filters={[
                    <TextInput source='title' />,
                    <DateInput defaultValue={moment().startOf('date').toDate()} source='from_date' parse={(v: any) => moment(v).startOf('date').toISOString()} />,
                ]}
                pagination={<MyPagination />}>
                <Datagrid
                    rowClick='show'
                    dir='rtl'
                    align='right'
                    bulkActionButtons={
                        <div style={{ display: 'flex', flexDirection: 'row', gap: 4 }}>
                            <CopyParamsToClipBoardButton />
                            <BulkDeleteButton />
                        </div>
                    }>
                    <FunctionField
                        render={(record: any) => (
                            <div className='text-center'>
                                <span style={{ direction: 'rtl' }} className='block font-semibold'>{`${moment(record.time).format('DD/MM/YY')}`}</span>
                                <span style={{ direction: 'rtl' }} className='block '>{`${moment(record.time).format('HH:mm')}`}</span>
                            </div>
                        )}
                        label='זמן'
                    />
                    <FunctionField
                        render={(record: any) => (
                            <div>
                                <Link
                                    className='self-center hover:underline'
                                    onClick={(e) => {
                                        e.stopPropagation();
                                    }}
                                    to={`/service/${record.service_id}`}>
                                    <span className='block font-semibold'>{record.title}</span>
                                </Link>
                                <span className='block text-xs'>{record.sub_title?.he}</span>
                            </div>
                        )}
                        textAlign='right'
                        label='כותרת'
                    />

                    <NumberField source='registered' textAlign='right' label='רשומים' sortable={false} align='center' />

                    <FunctionField label='מחיר' fontSize={12} render={(record: any) => `${record.price}₪`} />
                    <ArrayField source='host' label='יועצת' textAlign='right'>
                        <SingleFieldList resource='instructor' linkType={false}>
                            <FunctionField
                                render={(record: any) => (
                                    <Link
                                        className='text-sm font-medium hover:underline'
                                        to={'/instructor/' + record._id}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                        }}>
                                        <span>{record.name}</span>
                                    </Link>
                                )}
                            />
                        </SingleFieldList>
                    </ArrayField>
                    <ReferenceArrayField reference='company' source='hide_for.companies' label='מוסתר ל' textAlign='right'>
                        <SingleFieldList resource='company'>
                            <ChipField source='english_name' />
                        </SingleFieldList>
                    </ReferenceArrayField>
                    <BooleanField source='extra.zoom_join_required' label='לעלות למפגש' />
                    <BooleanField source='extra.zoom_test_required' label='טסט' />
                    <FunctionField
                        label='הקלטה'
                        variant='caption'
                        textAlign='center'
                        render={(record: any) => `${record.record ? 'קיימת הקלטה' : record.should_record ? 'יוקלט' : ''}`}
                    />
                    <FunctionField
                        label='הערות'
                        textAlign='right'
                        render={(record: any) => {
                            const info: { text: string; type: 'warning' | 'info' }[] = [];
                            if (!record.zoom) info.push({ text: 'אין קישור זום', type: 'warning' });
                            if (!record?.sub_title?.he) info.push({ text: 'אין כותרת משנה בעברית', type: 'warning' });
                            if (!record?.sub_title?.en) info.push({ text: 'אין כותרת משנה באנגלית', type: 'warning' });
                            if (!record.image) info.push({ text: 'אין תמונה', type: 'warning' });
                            if (!record.english_title) info.push({ text: 'אין שם מפגש באנגלית', type: 'warning' });
                            if (record.registration_limit_on) info.push({ text: `הגבלת משתתפים: ${record.max_register}`, type: 'info' });
                            if (!(record?.for_pregnant || (typeof record?.age?.from === 'number' && typeof record?.age?.to === 'number') || record.tags?.length))
                                info.push({ text: `אין תיוג`, type: 'warning' });
                            return (
                                <div className='whitespace-pre-wrap'>
                                    {info.map((item) => (
                                        <Typography variant='body1' fontSize={11} width={120} color={item.type === 'warning' ? '#CA0B00' : '#00CA0b'}>
                                            {`${item.text}`}
                                        </Typography>
                                    ))}
                                    {record?.extra?.text && (
                                        <Typography whiteSpace={'pre-wrap'} variant='body1' fontSize={11} width={120}>
                                            {`${record?.extra?.text}`}
                                        </Typography>
                                    )}
                                    {(record?.age?.from || record?.age?.to) && (
                                        <Typography variant='body1' fontSize={11} width={120}>
                                            {`${record?.age?.from || record?.age?.to ? 'גילאים' : ''}: ${record?.age?.from ?? ''}-${record?.age?.to ?? ''}`}
                                        </Typography>
                                    )}
                                </div>
                            );
                        }}
                    />
                    <FunctionField
                        textAlign='right'
                        label='קישור למפגש'
                        render={(record: any) => (
                            <IconButton
                                size={'small'}
                                color='primary'
                                onClick={(e) => {
                                    e.stopPropagation();
                                    navigator.clipboard.writeText(`https://app.grouphug.online/he/group-session/${record?.id}`);
                                    notify('קישור למפגש הועתק', { type: 'success' });
                                    return;
                                }}>
                                <FaLink />
                            </IconButton>
                        )}
                    />
                </Datagrid>
            </List>
        </>
    );
};
export default MeetingList;
